.route__createDeal {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 20px 80px;

    &__wrap {
        width: 500px;
        height: auto;
        padding: 10px;
        background-color: white;
        box-shadow: 0 0 4px rgba(0,0,0,0.4);
        margin: 0 auto;

        display: flex;
        flex-direction: column;

        &__element {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 20px 0;
            border-bottom: 1px solid rgba(128, 128, 128, 0.282);

            &:last-child {
                border-bottom: none;
            }
            &:first-child {
                padding-top: 0;
            }
            &__articles {
                width: 100%;
                height: auto;
                display: grid;
                grid-template-columns: 70px 1fr;
                grid-template-rows: auto;
                grid-auto-rows: auto;
                margin: 10px 0;
                column-gap: 10px;
                row-gap: 15px;

                &__btn {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #fb6363;
                    color: white;
                    border-radius: 5px;
                    cursor: pointer;

                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: red;
                    }
                }
            }
        }
        &__finalBtn {
            padding: 5px 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            background-color: #2185e8;
            color: white;
            cursor: pointer;

            transition: background-color 0.3s ease;
            
            &:hover {
                background-color: #1760a8;
            }
        }
        &__infoP {
            color: red;
            text-align: center;
            margin-top: 20px;
        }
    }
}