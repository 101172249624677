@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans";
}
html, body, #root {
  width: 100%;
  height: 100%;
}
#root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
}