.route__dashboard {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 20px 80px;

    & .customComponents__table {
        box-shadow: 0 0 4px rgba(0,0,0,0.5);
    }
    &__filters {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;
        gap: 10px;

        & > p {
            padding: 5px 10px;
            border: 1px solid black;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease, border-color 0.3s ease;

            &.active {
                border-color: #2185e8;
                background-color: #8fc7ff;
            }
        }

        &__createBtn {
            padding: 5px 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            background-color: #2185e8;
            color: white;
            cursor: pointer;

            transition: background-color 0.3s ease;
            
            &:hover {
                background-color: #1760a8;
            }
        }
    }
    &__kpiList {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 10px;
        margin: 20px 0;

        &__kpi {
            width: 100%;
            height: auto;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            background-color: white;
            padding: 10px;

            box-shadow: 0px 0px 4px rgba(0,0,0,0.3);
        }
    }
}