.route__users {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 10px;
}

.route__editUser__addUser {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    gap: 30px;
    &__userInfo {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 30px;
    }
}
