.route__login {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__wrap {
        background: #FFFFFF;
        border-radius: 5px;
        width: 350px;
        padding: 50px 40px;
        box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.75);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & > img {
            width: 145px;
            height: auto;
            margin-bottom: 20px;
        }
        &__input {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 15px;

            & > span {
                width: 100%;
                margin-bottom: 5px;
            }
            & > input {
                width: 100%;
                height: 40px;
                background: #FFFFFF;
                border: 1px solid #9CA3AF;
                border-radius: 5px;
                outline: none;
                padding: 0 10px;
            }
        }
        &__button {
            width: 100%;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            border-radius: 5px;
            cursor: pointer;
            
            background-color: #0458AD;
            color: #FFFFFF;

            transition: background-color 0.3s ease;

            &:hover {
                background-color: #02488e;
            }
        }
        &__infoP {
            margin-top: 20px;
            color: red;
        }
    }
}