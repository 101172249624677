.component__header {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px 80px;
    align-items: center;
    justify-content: space-between;

    &__leftSide, &__rightSide {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
        &__logo{
            width: auto;
            max-height: 30px;
        }
        &__menu {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;
            padding: 3px -10px;
            color: #797979;
            cursor: pointer;
            transition: all 0.3s ease;
            &:after {
                content: '';
                height: 3px;
                width: 50px;
                position: absolute;
                left: calc(50% - 25px);
                bottom: -5px;
                background-color: transparent;
                transition: all 0.3s ease;
              }
            &--active {
                font-family: Poppins;
                font-size: 18px;
                font-weight: 400;
                line-height: 27px;
                letter-spacing: 0em;
                text-align: left;
                color: #6d71f9;
                cursor: pointer;
                position: relative;
                &:after {
                    background-color: #6d71f9;
                  }
            }
        }
    }
    
}
