@font-face {
    font-family: "Montserrat";
    src: url("../../Fonts/Montserrat-SemiBold.ttf");
}
.customComponents__radio {
    width: auto;
    height: 28px;
    box-sizing: border-box;
    color: #00a3ff;
    border-radius: 26px;
    justify-content: space-around;
    align-items: center;
    div {
        font-family: Monteserrat;
        font-weight: 400;
        font-size: 9px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 17px;
        border-radius: 25px;
        transition: all 0.3s ease;
        cursor: pointer;
        padding: 0 3px;
    }
    .activeRadio {
        background-color: #00a3ff;
        color: #edeef6;
    }
}
.customComponents__radio{
    p{
        font-family: Montserrat;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: #5e606f;
    
        font-family: Montserrat;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: #5e606f;
        border: none;
        background-color: transparent;
        cursor: pointer;
        img{
            margin-right: 5px;
        }
    }
}
body.dark{
    .customComponents__radio {
        box-sizing: border-box;
        p {
            color: white;
        }
        .activeRadio {
            background-color: #00a3ff;
            color: #edeef6;
        }
    }
}



//Sidebar
.component__sidebar__radioContent{
    width: 150px !important;
    margin-top: 10px;
    .customComponent__radio__yes, .customComponent__radio__no{


    }
}
.component__sidebar__radioContent div{
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    p{
        margin-left: 10px;
    }
    
    
}